@use "../../styles/veriables" as v;
@use "../../styles/mixins" as m;


.navbar {
    color: v.$color1 !important;


    
    .menubar {
        background-color: v.$color2;
        
    }

    .dracar {
        cursor: pointer;
       font-weight: 700;
        font-size: 14px;
       color: #c9c6c6;

     

        @media (min-width: 768px) {
            font-size: 25px;
        }
    }
   
    
}





